<template>
  <b-row>
    <b-col lg="12">
      <b-button
        class="my-1 mobile-responsive"
        style="float: right"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        v-b-modal.modal-add-link
      >
        <span class="align-middle">Add Link</span>
      </b-button>
    </b-col>
    <b-col lg="12" md="12">
      <b-card>
        <b-row>
          <b-col lg="3" md="3" class="mt-1" v-for="link in links">
            <b-link @click="redirect(link.link)" class="mr-1">
              <feather-icon
                icon="LinkIcon"
                class="mr-50"
                style="margin-top: -4px"
              />
              <u>{{ link.name }}</u>
            </b-link>
            <feather-icon
              icon="Edit2Icon"
              color="green"
              class="ml-50"
              style="margin-top: -4px"
              v-if="userData.role == 'Zircly Admin'"
              v-b-modal.modal-edit-link
              @click="selected_link = link"
            />
            <feather-icon
              icon="DeleteIcon"
              color="red"
              class="ml-50"
              style="margin-top: -4px"
              v-if="userData.role == 'Zircly Admin'"
              @click="removeLink(link)"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <!--     
        <b-col lg="3" md="3" v-for="link in links">
            <b-card>
                <b-card-title class="text-primary">
                    {{link.name}}
                </b-card-title>
                    <p>
                        {{link.description}}
                    </p>
                    <b-button style="margin-left:33%;"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="redirect(link.link)"
                        >
                        <span class="align-middle">Go</span>
    
                        <feather-icon
                            icon="ArrowRightCircleIcon"
                            class="ml-50"
                        />
                    </b-button>
                
            </b-card>
        </b-col> -->

    <b-modal
      id="modal-add-link"
      ref="modal-add-link"
      title="Add Link"
      ok-title="submit"
      @ok="formSubmitted"
      @show="resetModal"
      @hidden="resetModal"
      cancel-variant="outline-danger"
    >
      <b-overlay :show="isBusy" rounded="sm">
        <b-form>
          <b-form-group label="Enter Name" label-for="name">
            <b-form-input
              id="name"
              placeholder="Enter name"
              v-model="new_link.name"
            />
          </b-form-group>
          <!-- <b-form-group
                    label="Enter Deacription"
                    label-for="description"
                    >
                    <b-form-textarea
                    id="description"
                    placeholder="Textarea"
                    name="description"
                    rows="3"
                    v-model="new_link.description"
                    />
                </b-form-group> -->
          <b-form-group label="Enter Link/URL" label-for="link">
            <b-form-input
              id="link"
              placeholder="Enter Link/URL"
              v-model="new_link.link"
            />
          </b-form-group>
        </b-form>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="outline-danger" @click="cancel()"> Cancel </b-button>
        <b-button :disabled="isBusy" variant="success" @click="ok()">
          <b-spinner v-if="isBusy" small class="mr-50"></b-spinner>
          Submit
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-edit-link"
      ref="modal-edit-link"
      title="Edit Link"
      ok-title="submit"
      @ok="formUpdated"
      @show="resetModal"
      @hidden="resetModal"
      cancel-variant="outline-danger"
    >
      <b-overlay :show="isBusy" rounded="sm">
        <b-form>
          <b-form-group label="Enter Name" label-for="name">
            <b-form-input
              id="name"
              placeholder="Enter name"
              v-model="selected_link.name"
            />
          </b-form-group>
          <!-- <b-form-group
                    label="Enter Deacription"
                    label-for="description"
                    >
                    <b-form-textarea
                    id="description"
                    placeholder="Textarea"
                    name="description"
                    rows="3"
                    v-model="selected_link.description"
                    />
                </b-form-group> -->
          <b-form-group label="Enter Link/URL" label-for="link">
            <b-form-input
              id="link"
              placeholder="Enter Link/URL"
              v-model="selected_link.link"
            />
          </b-form-group>
        </b-form>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="outline-danger" @click="cancel()"> Cancel </b-button>
        <b-button :disabled="isBusy" variant="success" @click="ok()">
          <b-spinner v-if="isBusy" small class="mr-50"></b-spinner>
          Update
        </b-button>
      </template>
    </b-modal>
  </b-row>
</template>


<script>
import store from "@/store";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar,
  BSidebar,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VBTooltip,
    BBadge,
    BProgress,
    BProgressBar,
    BSidebar,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      links: [],
      new_link: {
        name: "",
        description: "",
        link: "",
      },

      selected_link: {
        hashid: "",
        name: "",
        description: "",
        link: "",
      },
      userData: this.$cookies.get("userData"),
    };
  },
  created() {
    this.getLinks();
  },
  methods: {
    resetModal() {
      this.new_link = {
        name: "",
        description: "",
        link: "",
      };
    },
    formSubmitted(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.new_link.name == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Link Name is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Link Name is Required`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else if (this.new_link.link == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Link URL is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Link URL is Required`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else {
        this.isBusy = true;
        axios
          .post("/quick-links", this.new_link)
          .then((response) => {
            this.isBusy = false;
            if (response.status == "201") {
              this.getLinks();
              this.$refs["modal-add-link"].hide();
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Quick Links created successfully",
              //     icon: "EditIcon",
              //     variant: "primary",
              //   },
              // });
              this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Quick Links created successfully',
          icon: 'EditIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
            }
          })
          .catch((error) => {
            this.isBusy = false;
            for (var err in error.response.data.errors) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Error in " + err,
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: error.response.data.errors[err][0],
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'EditIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            }
          });
      }
    },
    formUpdated(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.selected_link.name == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Link Name is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link Name is Required',
          icon: 'EditIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
      } else if (this.selected_link.link == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Link URL is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Link URL is Required`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else {
        this.isBusy = true;
        axios
          .patch(
            "/quick-links/" + this.selected_link.hashid,
            this.selected_link
          )
          .then((response) => {
            console.log(response);
            this.isBusy = false;
            if (response.statusText == "Accepted") {
              this.getLinks();
              this.$refs["modal-edit-link"].hide();
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Quick Links Updated successfully",
              //     icon: "EditIcon",
              //     variant: "primary",
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: `Quick Links Updated successfully`,
                icon:'EditIcon',
                variant : 'success',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"successful-info",
              hideProgressBar : true,
                  })
            }
          })
          .catch((error) => {
            this.isBusy = false;
            for (var err in error.response.data.errors) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Error in " + err,
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: error.response.data.errors[err][0],
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'EditIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            }
          });
      }
    },
    getLinks() {
      axios.get("/quick-links").then((response) => {
        if (response.data.success) {
          this.links = response.data.data;
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: "Quick Links fetched successfully",
          //       icon: "EditIcon",
          //       variant: "primary",
          //     },
          //   });
        }
      });
    },

    redirect(link) {
      window.open(link, "_blank");
    },
    removeLink(link) {
      var linkId = link.hashid;
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete this link -  ${link.name}, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/quick-links/" + linkId)
            .then((response) => {
              console.log(response);
              if (response.statusText == "No Content") {
                this.getLinks();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Quick Links Deleted successfully",
                //     icon: "EditIcon",
                //     variant: "primary",
                //   },
                // });
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Quick Links Deleted successfully',
                  icon: 'EditIcon',
                  variant: 'success',
                  hideClose: true,
                },
              }, {
                timeout: 2000, 
                position: 'bottom-center',
                toastClassName:"successful-info",
                hideProgressBar : true,
              }); 
              }
            })
            .catch((error) => {
              for (var err in error.response.data.errors) {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Error in " + err,
                //     icon: "EditIcon",
                //     variant: "danger",
                //     text: error.response.data.errors[err][0],
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'EditIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            });
        }
      });
    },
  },
};
</script>